import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeatureVideo from '../components/left-feature-video';
import FeaturePararaph from '../components/feature-paragraph'
import {AbaxElectricalTestimonial} from '../components/testimonial';

import FreeTrialButton from '../components/free-trial-button';

import xeroLogoSrc from '../images/tk-xero-sync.svg';

import ListItem from '../components/list-item';


const Xero = () => (
  <Layout>
    <SEO
    	title="Xero Timesheet App with Payroll Integration"
    	description="The Best Xero Timesheet App. Works with Xero Payroll"
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Seamless Timesheet app <br /> for
			            <span className="text-highlight-600"> Xero Payroll</span>
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Xero is an amazing accounting platform and TimeKeeper is <span className="italic">(we think)</span> a great time and attendance platform.
			          </p>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			             Why not combine the two? Record detailed employee timesheets in TimeKeeper and send instantly to Xero Payroll in a few clicks.
			          </p>
			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <a a href="https://www.xero.com" className="relative block flex justify-center w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={xeroLogoSrc} alt="Xero TimeKeeper Sync Integration" />
			            </a>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <LeftFeatureVideo
				    	sectionTitle="Beautiful timesheet integration"
				    	title="How does the integration work?"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          TimeKeeper and Xero are both hosted in the cloud, so it's a simple straightforward case of telling us what timesheets you want to send to Xero for which employees.
						        </FeaturePararaph>
						        <FeaturePararaph>
						         In a few clicks, the data is transferred directly to your Xero Payroll for you to process your pay run.
						        </FeaturePararaph>
						        <div className="mt-8">
							        <ListItem
							          	title="Does it take into account employee working patterns and overtime rules?"
							          	description="Yes! TimeKeeper has a sophisticated timesheet and overtime engine built in, when we export to Xero we export the correct amount of regular and overtime hours for each employee based on their working pattern."
							           	icon={
							           		<svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
							           	}
							           />
							           <ListItem
							          	title="Does it support matching time against Xero Earning Rates?"
							          	description="Yes! As part of the process of exporting, we'll help guide you through mapping your regular and overtime time rates in TimeKeeper to the equivalent Xero Earning Rates."
							          	icon={
							           		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" fillRule="evenodd"></path></svg>
							           	}
							           />
							           <ListItem
							          	title="Does it support Payroll UK, Payroll AU and Payroll NZ?"
							          	description="Currently we support Payroll UK and Payroll NZ Xero - we do not support Payroll AU users as of yet."
							          	icon={
							           		<svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
							           	}
							           />
							           <ListItem
							          	title="Can you help our company set this up?"
							          	description="Yes - we will jump on a call with you free of charge to set up your integration between TimeKeeper and Xero as well as answer any queries you may have. It's really simple."
							          	icon={
							          		<svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8"><path d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clipRule="evenodd" fillRule="evenodd"></path></svg>
							           	}
							           />
							    </div>
						    </div>
				    	}
				    	rightChild={
				    		<div className="flex w-full justify-center mt-4">
				              <iframe
				                width="560"
				                height="315"
				                src="https://www.youtube.com/embed/OKMEOUM3Pt0"
				                title="Xero TimeKeeper Demo"
				                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				                frameBorder="0"
				                webkitallowfullscreen="true"
				                mozallowfullscreen="true"
				                allowFullScreen
				              />
				            </div>
				    	} />
				</div>
				<AbaxElectricalTestimonial />
				<FreeTrial
					part1={<span>Start your 14 day free trial to <br /> level up your <span className="text-highlight">Xero Payroll</span> process</span>}
				/>
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default Xero;
